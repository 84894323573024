<template>
  <v-form >
    <v-row class="mt-10 ">
      
      <v-col class=" mr-16" cols="12">
         <v-carousel :show-arrows="true">
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
      </v-col>
      <v-col cols="12" class="ml-8">
        <h1>Dashboard</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card class="mt-1 mx-auto ml-8" max-width="400">
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="cyan"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :labels="labels"
              :value="value"
              color="white"
              line-width="2"
              padding="16"
            ></v-sparkline>
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">Taxes</div>
            <div class="subheading font-weight-light grey--text">Title</div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> </v-icon>
            <span class="caption grey--text font-weight-light"></span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card class="mt-1 mx-auto" max-width="400">
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="red"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :labels="labels"
              :value="value"
              color="white"
              line-width="2"
              padding="16"
            ></v-sparkline>
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">SALES</div>
            <div class="subheading font-weight-light grey--text">Title</div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> </v-icon>
            <span class="caption grey--text font-weight-light"></span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card class="mt-1 mx-auto mr-8" max-width="400">
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="orange"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :labels="labels"
              :value="value"
              color="white"
              line-width="2"
              padding="16"
            ></v-sparkline>
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">Sales non taxes</div>
            <div class="subheading font-weight-light grey--text">Title</div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> </v-icon>
            <span class="caption grey--text font-weight-light"></span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  data: () => ({
     items: [
          {
            src: "http://myfastax.com/src/assets/fastax1.PNG",
          },
          {
            src: "http://myfastax.com/src/assets/fastax2.PNG",
          },
          {
            src: "http://myfastax.com/src/assets/fastax1.PNG",
          },
          {
            src: "http://myfastax.com/src/assets/fastax2.PNG",
          },
        ],
  
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    labels: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    value: [],
  }),
  mounted() {
   
    this.ConsultarReportesGrafica();
  },
  methods: {
    ConsultarReportesGrafica() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 12);
      parametros.append("idCliente", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);

            response.data.forEach((element) => {
              this.value.push(element.sales);
            });

            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
  },
  AccionMenu(opcion) {
      switch (opcion) {
        
        case "Sales Taxes":
          this.$router.push("/SalesTaxes").catch((failure) => {});
          break;

        case "Bookkeeping":
          this.$router.push("/Bookkeeping").catch((failure) => {});
          break;

        

        default:
          break;
      }
    },
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>

