<template>
  <v-form class="mt-13 ">
    <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
      <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
      <v-tab>
        <v-btn text x-large
          ><v-icon left>mdi-chat</v-icon>{{nombreChat}}
          <h5>{{ datos[0].nombreTarea }}</h5></v-btn
        >
      </v-tab>
      <v-tab-item>
        <v-card >
          <v-card-text>
            <v-form ref="loginForm"   lazy-validation>
              <v-card-text  class="chat ml-14">
                <v-row dense>

                  <v-col cols="12">

                    <v-container>
                      <h2 class="mb-3 " >CHAT</h2>
                      
                      <ChatPersonal  :datos="datos" @showName="mostrarNombre" />

                       <Archivos
                        v-if="estatusArchivo"
                        :nodo="datos"
                        @cerrar="CerrarArchivos"
                        :estatus="estatusArchivos"
                      />

                      <input
                        type="file"
                        ref="boton"
                        class="d-done"
                        @change="BuscarImagen($event)"
                      />
                    </v-container>

                    <v-btn
                      color="#EF6C00"
                      class="mb-5 ml-5"
                      @click="(estatusArchivo = true), (estatusArchivos = true)"
                      large
                      dark
                    >
                      <v-icon x-large color="white"> mdi-file </v-icon>
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      class="mb-5 ml-5"
                      @click="confirmacion = true"
                      large
                      dark
                      v-if="rol == 1"
                    >
                      <v-icon x-large color="white"> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row dense>
                  <v-col cols="6" sm="1">
                    
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="confirmacion" persistent max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right>mdi-delete</v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col
                        ><h3>
                          Are you sure you want to delete this task?
                        </h3></v-col
                      >
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmacion = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      large
                      dark
                      @click="EliminarTarea(), (estatusChat = false)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>

  </v-form>
    
</template>

<script>
import ChatPersonal from "../components/ChatPersonal"; //componente del chat, este es el mismo que beatiful-chat
import { firebase, storage, db } from "../firebase"; //librerias de firebase para usar sus herramientas
import Archivos from "../components/Archivos"; //componente que permite la visualizacion de los archivos enviados en el chat
import EventBus from "../bus";
export default {
  components: {
    ChatPersonal,
    Archivos,
  },
  data: () => ({
    idTarea:'',
    rol: sessionStorage.getItem("rol"),
    file: null, //contendrá el archivo que se mandará al chat, tiene todos los datos del archivo.
    estatusChat: true, //define cuando el chat estará visible
    rol: sessionStorage.getItem("rol"), 
    estatusArchivo: false, //define cuando la lista de archivos enviados estará visible al usuario
    confirmacion: false,
    datos:[], //contiene los datos del chat
    nombreChat:''
  
  }),
  mounted() {
   // this.ConsultarDatosTarea();
    //this.ConsultarEmpleadosXTarea();
    this.datos=this.$route.params.id;
    EventBus.$emit("hint-taskcenter");
    

  },
  methods: {
     mostrarNombre: function(className) {
      this.nombreChat=className
    },
    
    ConsultarDatosTarea(){
      let parametros = new URLSearchParams();
      this.idTarea = this.$route.params.id;
     
      parametros.append("accion", 20);
      parametros.append("idTarea", this.idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            response.data.forEach((element)=>{
              this.datos.push(element);
            });

            console.log(datos);
            
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );

    },
    EnviarURL(url, name) {
      var nombre = sessionStorage.getItem("fullname");
      var urk = url;
      var mensaje = name;
      db.collection(this.$route.params.id)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          message: mensaje,
          url: url,
          type: "file",
          download: true,
          time: Date.now(),
          status:1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      //this.ConsultarColaboradores();
      var temp = this.$route.params.id.split('-');
        temp.forEach((element) => {
          if(element!=sessionStorage.getItem("uid") && element!='chat'){
            this.Enviarnoty(element);
          }
        })
    },
    
    Enviarnoty(idDestinatario) {
      var nombre = "";
      nombre=sessionStorage.getItem("fullname");
      db.collection("noty" +idDestinatario)
        .doc("notificacionTarea" + this.datos)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: sessionStorage.getItem("fullname"),
          type: "2",
          message: "New message:  " + sessionStorage.getItem("nombre"),
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      this.ConsultarMensajes();
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.$route.params.id)
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        console.log(urlDescarga);
        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    async subirImagen() {
      try {
        const refImagen = storage.ref().child("Archivos" + this.$route.params.id);

        const res = await refImagen.put(file);
      } catch (error) {
        conole.log(error);
      }
    },
    CerrarArchivos() {
      this.estatusArchivo = false;
    },
    LimpiarNotificaciones() {
      var nombre = sessionStorage.getItem("uid");
      let ref = db.collection("noty" + nombre).doc("");

      ref
        .update({
          notificacion: false,
        })
        .then(function () {
          this.AccionMenu(item.type);
          //this.Notificar();
          //console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        });
    },
  },
};
</script>

<style>
.chat {
 
  max-width: 90%;
  
}


</style>
