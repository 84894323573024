<template>
  <v-form class="mt-5">
    <v-row class="mt-10 ml-5">
      <v-col cols="12">
        <h1>Bookkeeping</h1>
       
        Upload new file
        <input
          type="file"
          ref="boton"
          class="d-done"
          @change="BuscarImagen($event)"
        />

        <div>
          <v-data-table
            :headers="headers"
            :items="archivos"
           
            class="elevation-1"
          >
          
          <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="indigo" class="mr-2" @click="downloadItem(item.ruta)">
                  mdi-download
                </v-icon>

                 <v-icon color="indigo" class="mr-2" @click="EliminarDocumento(item)">
                  mdi-delete
                </v-icon>
            </template></v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import moment from "moment";
import { db, storage } from "../firebase";

export default {
  
  data() {
    return {
      rol: sessionStorage.getItem("rol"),
      participanteChat: "",
      file: null,
      urlTemporal: "",
      encargados: false,
      estatusChat: true,
      rol: sessionStorage.getItem("rol"),
      estatusSelect: false,
      estatusArchivo: false,
      confirmacion: false,
      totalDesserts: 0,
      archivos: [],
      loading: true,
      options: {},
      headers: [
        {
          text: "Name file",
          align: "start",
          sortable: false,
          value: "nombreArchivo",
        },
        { text: "Fecha ", value: "fechaEntrega" },
        { text: "Accions", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
   this.ConsultarArchivos();
  },
  methods: {
    
      Enviarnoty() {
      var nombre = "";
      db.collection("noty7")
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: "1", 
          message: "New File of :  " + sessionStorage.getItem("fullname"),
          time: Date.now(),
          idTarea: '',
          estatus:true
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      this.ConsultarMensajes();
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + sessionStorage.getItem("uid"))
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        console.log(urlDescarga);
        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    EnviarURL(urlDescarga, fileName) {

      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 14);
      parametros.append("ruta", urlDescarga);
      parametros.append("nombreArchivo", fileName);
      parametros.append("idCliente", sessionStorage.getItem("uid"));
     // parametros.append("periodo", this.editedItem.name);
     // parametros.append("fechaEntrega", this.editedItem.lastName);
     // parametros.append("tipo", this.editedItem.dependientes);
     //parametros.append("estatus", this.editedItem.estadoCivil);


      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.Enviarnoty();
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarArchivos() {

      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 15);
      parametros.append("idCliente", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.archivos=response.data;
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    downloadItem(ruta) {

     
           axios({

            method: "get",
            url: ruta,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
            
          })
            .then(
              function (response) {

                console.log(response.data);
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement('a');
                link.href = data;
                link.download=name;
                link.click();

              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
      
    },
    EliminarDocumento(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 22);
      parametros.append("idDocumento", item.idDocumento);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarArchivos();
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + sessionStorage.getItem("uid"))
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        console.log(urlDescarga);
        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>