<script>
import EventBus from "../bus"; 
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [
      {
        text: "VendorQB",
        align: "start",
        sortable: false,
        value: "VENDORQB",
      },
      {
        text: "Vendor",
        align: "start",
        sortable: false,
        value: "Vendor",
      },
      { text: "Construction", value: "Construction" },
      { text: "Ironwork", value: "Ironwork" },
      { text: "Beaty Salon", value: "Beaty Salon" },
      { text: "Bakery", value: "Bakery" },
      { text: "Restaurant", value: "Restaurant" },
      { text: "SuperMarket", value: "Supermarket" },
      { text: "Boutique", value: "Boutique" },
      { text: "Flower Shop", value: "Flower Shop" },
      { text: "Plumbing", value: "Plumbing" },
      { text: "Landscaping", value: "Landscaping" },
      { text: "Towing", value: "Towing" },
      { text: "Transport", value: "Transport" },
      { text: "Repairs", value: "Repairs" },
      { text: "Events", value: "Events" },
      { text: "Jewerly", value: "Jewerly" },
      { text: "ice_cream_shop", value: "ice_cream_shop" },
      { text: "Painting", value: "Painting" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    vendors: [],
    editedIndex: -1,
    editedItem: {
      VendorQB: "",
      Vendor: "",
      Construction: "",
      Ironwork: "",
      Beauty_Salon: "",
      Bakery: "",
      Restaurant: "",
      Supermarket: "",
      Boutique: "",
      Flower_Shop: "",
      Plumbing: "",
      Landscaping: "",
      Towing: "",
      Transport: "",
      Repairs: "",
      Events: "",
      Jewerly: "",
      Ice_Cream_Shop: "",
      Painting: "",
    },
    defaultItem: {
      VendorQB: "",
      Vendor: "",
      Construction: "",
      Ironwork: "",
      Beauty_Salon: "",
      Bakery: "",
      Restaurant: "",
      Supermarket: "",
      Boutique: "",
      Flower_Shop: "",
      Plumbing: "",
      Landscaping: "",
      Towing: "",
      Transport: "",
      Repairs: "",
      Events: "",
      Jewerly: "",
      Ice_Cream_Shop: "",
      Painting: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    EventBus.$emit("hint-taskcenter");
    this.initialize();
  },
  methods: {
    initialize() {
      this.ConsultarVendors();
    },

    editItem(item) {
      this.editedIndex = this.vendors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.vendors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.editedItem.idVendor = item.idVendor;
    },

    deleteItemConfirm() {
      this.vendors.splice(this.editedIndex, 1);
      this.closeDelete();
      this.EliminarVendor();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.vendors[this.editedIndex], this.editedItem);
      } else {
        this.vendors.push(this.editedItem);
      }
      this.Accion();
      this.close();
    },
    ConsultarVendors() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVendors.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.vendors = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    Accion() {
      if (this.editedIndex === -1) {
        this.IngresarVendor();
      } else {
        this.ModificarVendor();
      }
    },
    IngresarVendor() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 2);
      parametros.append("VendorQB", this.editedItem.VENDORQB);
      parametros.append("Vendor", this.editedItem.Vendor);
      parametros.append("Constructora", this.editedItem.Construction);
      parametros.append("Herreria", this.editedItem.Ironwork);
      parametros.append("SalonBelleza", this.editedItem.Beauty_Salon);
      parametros.append("Panaderia", this.editedItem.Bakery);
      parametros.append("Restaurante", this.editedItem.Restaurant);
      parametros.append("SuperMercado", this.editedItem.Supermarket);
      parametros.append("Boutique", this.editedItem.Boutique);
      parametros.append("Floreria", this.editedItem.Flower_Shop);
      parametros.append("Plomeria", this.editedItem.Plumbing);
      parametros.append("Jardineria", this.editedItem.Landscaping);
      parametros.append("Gruas", this.editedItem.Towing);
      parametros.append("Transporte", this.editedItem.Transport);
      parametros.append("Repairs", this.editedItem.Repairs);
      parametros.append("Events", this.editedItem.Events);
      parametros.append("Jewerly", this.editedItem.Jewerly);
      parametros.append("Ice_cream_Shop", this.editedItem.Ice_Cream_Shop);
      parametros.append("Painting", this.editedItem.Painting);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVendors.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.LimpiarCajas();
            this.vendors = response.data;
            //alert("Insert Susseful");
            this.ConsultarVendors();
            this.dialog = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarVendor() {
      let parametros = new URLSearchParams();

      parametros.append("accion", 3);
      parametros.append("idVendor", this.editedItem.idVendor);
      parametros.append("VendorQB", this.editedItem.VENDORQB);
      parametros.append("Vendor", this.editedItem.Vendor);
      parametros.append("Constructora", this.editedItem.Construction);
      parametros.append("Herreria", this.editedItem.Ironwork);
      parametros.append("SalonBelleza", this.editedItem.Beauty_Salon);
      parametros.append("Panaderia", this.editedItem.Bakery);
      parametros.append("Restaurante", this.editedItem.Restaurant);
      parametros.append("SuperMercado", this.editedItem.Supermarket);
      parametros.append("Boutique", this.editedItem.Boutique);
      parametros.append("Floreria", this.editedItem.Flower_Shop);
      parametros.append("Plomeria", this.editedItem.Plumbing);
      parametros.append("Jardineria", this.editedItem.Landscaping);
      parametros.append("Gruas", this.editedItem.Towing);
      parametros.append("Transporte", this.editedItem.Transport);
      parametros.append("Repairs", this.editedItem.Repairs);
      parametros.append("Events", this.editedItem.Events);
      parametros.append("Jewerly", this.editedItem.Jewerly);
      parametros.append("Ice_cream_Shop", this.editedItem.Ice_Cream_Shop);
      parametros.append("Painting", this.editedItem.Painting);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVendors.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.LimpiarCajas();
            this.vendors = response.data;
            //alert("Insert Susseful");
            this.ConsultarVendors();
            this.dialog = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarVendor() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idVendor", this.editedItem.idVendor);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVendors.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.LimpiarCajas();
            this.vendors = response.data;
            //alert("Insert Susseful");
            this.ConsultarVendors();
            this.dialog = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    LimpiarCajas() {
      this.Vendor = "";
      this.constructora = "";
      this.herreria = "";
      this.salonBelleza = "";
      this.panaderia = "";
      this.restaurante = "";
      this.superMercado = "";
      this.boutique = "";
      this.floreria = "";
      this.plomeria = "";
      this.jardineria = "";
      this.gruas = "";
      this.transport = "";
    },
  },
};
</script>
